<script setup lang="ts">
import { useCanSee } from '~/composables/useCanSee'

const props = defineProps({
  fields: {
    type: Object,
    default: () => ({}),
  },
  slice: {
    type: Object,
    default: () => ({}),
  },
})

defineSlice({
  preview: 'SlicesGlueMagazineFeatureMagazineFeature.jpg',
  slots: [{ name: 'default' }],
  description: 'Feature image with a description.',
  fields: {
    image: {
      label: 'Image',
      type: 'media',
      editorLabel: true,
      breakpoints: {
        default: { width: 900, height: 400 },
        lg: { width: 1600, height: 760 },
      },
    },
    mobileImage: {
      label: 'Mobile Image',
      type: 'media',
      breakpoints: {
        default: { width: 375, height: 420 },
      },
    },
    showContentBlock: { label: 'Show Content Block', type: 'checkbox', default: true },
    contentPosition: { label: 'Content Position', type: 'select', options: { 'top-left': 'Top Left', 'top-right': 'Top Right', 'left': 'Left', 'right': 'Right', 'bottom-left': 'Bottom Left', 'bottom-right': 'Bottom Right' } },
    offsetImage: { label: 'Offset Image', type: 'select', options: { none: 'None', left: 'Left', right: 'Right' } },
    showBottomBorder: { label: 'Show Bottom Border', type: 'checkbox', default: true },
    subTitle: { label: 'Sub-Title', type: 'text' },
    title: { label: 'Title', type: 'text' },
    description: { label: 'Description', type: 'wysiwyg' },
    link: { label: 'Link', type: 'link', enabled: false },
    videoMode: { type: 'select', label: 'Video Playback Settings', options: { clickToActivate: 'Click to Activate', playAutomatically: 'Play Automatically' } },
    wistiaId: { type: 'text', label: 'Wistia ID', enabled: false },
    videoControlsLocation: { type: 'select', label: 'Video Controls Location', options: { 'top-left': 'Top Left', 'top-center': 'Top Center', 'top-right': 'Top Right', 'middle-left': 'Middle Left', 'middle-center': 'Middle Center', 'middle-right': 'Middle Right', 'bottom-left': 'Bottom Left', 'bottom-center': 'Bottom Center', 'bottom-right': 'Bottom Right' } },
    desktopHeight: { type: 'number', label: 'Desktop Height', enabled: false },
    mobileHeight: { type: 'number', label: 'Mobile Height', enabled: false },
  },
  name: { label: 'Magazine Feature', group: 'Glue' },
  templates: [{ label: 'Magazine Feature', fields: { contentPosition: { value: 'bottom-right' }, description: { value: '<p><\/p><hr><p><strong><br>Every Sunday through March 10<\/strong><sup><strong>th<\/strong><\/sup><strong><br><\/strong><br>Elevate your Sundays with an extravagant all-inclusive brunch featuring gospel choir Shaback.<strong><br><br><\/strong><\/p><p>Indulge in the appetizer and dessert buffet, featuring a seafood bar, charcuterie, artisan pastries, and family-style main courses served at your table \u2013 all are included. Unlimited Champagne, Mimosas, and Bloody Mary\u2019s are the cherry on top!<br><br><\/p><p><\/p><p><\/p><p><br><br><\/p>' }, desktopHeight: { value: 500 }, image: { value: 'https:\/\/atlantis-cms-assets.s3.us-east-2.amazonaws.com\/media\/Events\/2023\/gospel%20brunch\/gospel-brunch-0124-v7.png' }, link: { value: { text: 'join us', target: '_self', relationship: null, href: '\/new-page' } }, mobileImage: { value: 'https:\/\/atlantis-cms-assets.s3.us-east-2.amazonaws.com\/media\/Events\/2023\/gospel%20brunch\/gospel-brunch-0124-event-thumbnail.png' }, offsetImage: { value: 'none' }, subTitle: { value: 'Special Event' }, title: { value: 'GOSPEL BRUNCH AT CAF\u00C9 MARTINIQUE' }, videoControlsLocation: { value: 'middle-left' }, videoMode: { value: 'clickToActivate' }, wistiaId: { value: 'cj9ch0mgkg' } } }],
})

const canSee = ref(false)
const magazineFeature = ref(null)
useCanSee(magazineFeature, () => { canSee.value = true }, () => { canSee.value = false })

const showVideo = ref(false)
const { $voix } = useNuxtApp()

const isMobileImage = computed(() => {
  return (
    ($voix.breakpoint.value === 'default' || $voix.breakpoint.value === 'sm' || $voix.breakpoint.value === 'md')
    && props.fields.mobileImage.value?.[0]?.url
  )
})

const featureStyles = computed(() => {
  if (
    $voix.breakpoint.value === 'default'
    || $voix.breakpoint.value === 'sm'
    || $voix.breakpoint.value === 'md'
  ) {
    return { width: '100%' }
  }

  return { width: '350px' }
})

const heightStyles = computed(() => {
  if (
    ($voix.breakpoint.value === 'default' || $voix.breakpoint.value === 'sm')
    && props.fields.mobileHeight.enabled
  ) {
    return { height: `${props.fields.mobileHeight.value}px` }
  }

  if (
    $voix.breakpoint.value !== 'default'
    && $voix.breakpoint.value !== 'sm'
    && props.fields.desktopHeight.enabled
  ) {
    return { height: `${props.fields.desktopHeight.value}px` }
  }

  return {}
})

if (
  props.fields.wistiaId.enabled
  && props.fields.videoMode.value === 'playAutomatically'
) {
  showVideo.value = true
}
</script>

<template>
  <div
    ref="magazineFeature"
    class="relative w-full"
    :class="{
      'border-b-8 border-white': fields.showBottomBorder.value,
    }"
  >
    <ClientOnly>
      <div class="relative">
        <div
          class="w-full overflow-hidden transform duration-500 delay-500 max-h-[70vh] lg:max-h-auto lg:aspect-video"
          :class="{ 'translate-y-12 opacity-0': !canSee, 'opacity-100': canSee }"
          :style="heightStyles"
        >
          <template v-if="!showVideo">
            <VoixMedia v-if="!isMobileImage" :field="fields.image" :class="{ 'lg:ml-64': fields.offsetImage.value === 'left', 'lg:w-[85%]': fields.offsetImage.value === 'right' }" class="w-full h-full object-cover" />
            <VoixMedia v-else :field="fields.mobileImage" class="w-full" />
            <div
              v-if="
                fields.wistiaId
                  && fields.wistiaId.enabled
                  && fields.videoMode.value === 'clickToActivate'
                  && !showVideo
              "
              class="absolute z-20 inset-0 m-8 text-zinc-100 flex justify-center items-center"
            >
              <button @click="showVideo = true">
                <svg
                  width="100"
                  height="100"
                  viewBox="0 0 62 62"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="31"
                    cy="31"
                    r="30"
                    fill="black"
                    fill-opacity="0.4"
                    stroke="white"
                    stroke-width="1.5"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    transform="translate(24 21)"
                    d="M16.4265 9.12584C17.1123 9.50685 17.1123 10.4932 16.4265 10.8742L1.48564 19.1746C0.819113 19.5449 0 19.063 0 18.3005L0 1.69951C0 0.93703 0.819113 0.455062 1.48564 0.825357L16.4265 9.12584Z"
                    fill="white"
                  />
                </svg>
              </button>
            </div>
          </template>
          <div
            v-else
            class="h-full overflow-hidden object-cover flex justify-center items-center bg-black"
            :class="{
              'lg:ml-64': fields.offsetImage.value === 'left',
              'lg:w-[85%]': fields.offsetImage.value === 'right',
              'w-full': fields.offsetImage.value === 'none',
            }"
          >
            <glue-media-wistia
              :video-id="fields.wistiaId.value"
              :controls-location="fields.videoControlsLocation.value"
              class="w-full aspect-video relative z-30"
            />
          </div>
        </div>
        <div
          v-if="fields.showContentBlock.value"
          class="relative lg:absolute z-10 lg:ml-24 xl:ml-32"
          :class="{
            'lg:ml-6 lg:left-0 lg:top-0 lg:-mt-8':
              fields.contentPosition.value === 'top-left',
            'lg:right-0 lg:top-0 lg:-mt-8 lg:mr-6':
              fields.contentPosition.value === 'top-right',
            'lg:left-0 lg:top-0 lg:bottom-0 lg:ml-24 flex items-center':
              fields.contentPosition.value === 'left',
            'lg:right-0 lg:top-0 lg:bottom-0 lg:mr-24 flex items-center':
              fields.contentPosition.value === 'right',
            'lg:left-0 lg:bottom-0 lg:-mb-8 lg:ml-6':
              fields.contentPosition.value === 'bottom-left',
            'lg:right-0 lg:bottom-0 lg:-mb-8 lg:mr-6':
              fields.contentPosition.value === 'bottom-right',
          }"
          :style="featureStyles"
        >
          <div
            class="w-full lg:w-auto bg-white px-12 pt-8 pb-10 transform duration-1000 delay-700"
            :class="{ 'translate-y-6 opacity-0': !canSee, 'opacity-100': canSee }"
          >
            <div class="font-serif italic text-3xl tracking-wider font-light text-red-300">
              {{ fields.subTitle.value }}
            </div>
            <div
              class="uppercase text-3xl font-sans font-light leading-tight antialiased tracking-wide mt-2"
            >
              {{ fields.title.value }}
            </div>
            <div
              class="text-sm font-sans2 font-light antialiased mt-1"
              v-html="fields.description.value"
            />
            <a
              v-if="fields.link.enabled"
              class="block mt-8 glue-btn btn-ghost border-zinc-600 text-zinc-700 uppercase btn-ghost body-11 tracking-widest py-3 px-6"
              :href="fields.link.value?.href"
              :target="fields.link.value.target"
            >
              {{ fields.link.value.text }}
            </a>
            <slot />
          </div>
        </div>
      </div>
    </ClientOnly>
  </div>
</template>
